import { ADMIN_PROFILE_FAIL, ADMIN_PROFILE_REQUEST, ADMIN_PROFILE_RESET, ADMIN_PROFILE_SUCCESS, ADMIN_UPDATE_PROFILE_FAIL, ADMIN_UPDATE_PROFILE_REQUEST, ADMIN_UPDATE_PROFILE_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_RESET, USER_LIST_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT } from "../constants/UserConstants";

// LOGIN
export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
};

// ADMIN PROFILE
export const adminProfileDetailsReducer = (state = {user: {} }, action) => {
    switch (action.type) {
        case ADMIN_PROFILE_REQUEST:
            return { ...state, loading: true }
        case ADMIN_PROFILE_SUCCESS:
            return { loading: false, user: action.payload }
        case ADMIN_PROFILE_FAIL:
            return { loading: false, error: action.payload }
        case ADMIN_PROFILE_RESET:
            return { user : {} }
        default:
            return state;
    }
};

// ADMIN UPDATE PROFILE
export const adminUpdateProfileDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_UPDATE_PROFILE_REQUEST:
            return { loading: true }
        case ADMIN_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case ADMIN_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
};

// ADMIN GET ALL USERS
export const userListReducer = (state = {users: []}, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true }
        case USER_LIST_SUCCESS:
            return { loading: false, success: true, users: action.payload }
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        case USER_LIST_RESET:
            return { users: [] };
        default:
            return state;
    }
};