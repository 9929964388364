import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";
import Toast from "../LoadingError/Toast";
import { useDispatch, useSelector } from "react-redux";
import { listOfCategories, resetCategoryDelete } from "../../redux/actions/CategoryActions";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const MainCategories = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const dispatch = useDispatch();
  const toastId = React.useRef(null);

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const { success: deleteSuccess } = categoryDelete;

  const handleCategoryDelete = useCallback(() => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success("Category successfully deleted", ToastObjects);
    }
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(listOfCategories());
      dispatch(resetCategoryDelete());
      handleCategoryDelete();
    }
  }, [dispatch, deleteSuccess, handleCategoryDelete]);

  const handleEditClick = (category) => {
    setCategoryToEdit(category);
    setIsEditMode(true);
  };

  const handleEditComplete = (isSuccess) => {
    if (isSuccess && !toast.isActive(toastId.current)) {
      toastId.current = toast.success("Category successfully Updated", ToastObjects);
    }
    setCategoryToEdit(null);
    setIsEditMode(false);
  };

  const handleCategoryAdded = () => {
    toast.success("Category successfully Added");
  };

  return (
    <>
      <Toast />
      <section className="content-main categories">
        <div className="content-header">
          <h2 className="content-title">Categories</h2>
        </div>

        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row">
              {/* Create category */}
              <CreateCategory
                isEditMode={isEditMode}
                categoryToEdit={categoryToEdit}
                onEditComplete={handleEditComplete}
                onCategoryAdded={handleCategoryAdded}
              />
              {/* Categories table */}
              <CategoriesTable 
                onEditClick={handleEditClick} 
                onCategoryDelete={handleCategoryDelete}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainCategories;
