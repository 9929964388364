import React, { useState } from 'react';

const FileUpload = ({ fileInputRef, handleFileChange, fileType }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      handleFileChange(droppedFiles);
    }
  };

  const handleFileInputChange = (files) => {
    const selectedFiles = Array.from(files);
    handleFileChange(selectedFiles);
  };

  const containerStyle = {
    border: `2px dashed ${isDraggingOver ? '#007bff' : '#ddd'}`,
  };

  return (
    <div
      className="file-upload"
      style={containerStyle}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="drag-drop-area">
        <span className="icon"><i className="fas fa-cloud-upload-alt" /></span>
        <p>Drag or paste {fileType === 'image' ? 'image' : 'video'} here.</p>
        <input
          type="file"
          id="file-input"
          accept={fileType === 'image' ? 'image/*' : 'video/*'}
          style={{ display: 'none' }}
          ref={(el) => (fileInputRef.current = el)}
          key={Date.now()}
          onChange={(e) => handleFileInputChange(e.target.files)}
          multiple  
        />
      </div>
      <div
        className="btn btn-outline-secondary select-button"
        style={{ cursor: 'pointer' }}
        onClick={() => fileInputRef.current.click()}
      >
        <i className="fa fa-upload" aria-hidden="true"></i>
        Select {fileType === 'image' ? 'image' : 'video'}
      </div>
    </div>
  );
};

export default FileUpload;