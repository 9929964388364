import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from '../redux/actions/userActions';
import Message from '../components/LoadingError/Error';
import { SpinnerLoading } from "../components/LoadingError/Loading";
import Toast from "../components/LoadingError/Toast";

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin)
  const {error, loading, userInfo} = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/", { state: { userInfo } });
    }
  }, [userInfo, navigate]);

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password))
  }
  return (
    <>
      <Toast />
      <div className="admin-login">
        <div
          className="card shadow"
        >
          <div className="card-body">
            <h4 className="card-title mb-4 text-center">Sign in</h4>
            {error && <Message variant="alert-danger">{error}</Message>}
            <form onSubmit={submitHandler}>
              <div className="email-input-container mb-3">
                <input
                  className="form-control"
                  required
                  type="email" 
                  placeholder="Email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="password-input-container mb-3">
                <input
                  className="form-control"
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password && (
                  <i
                    className={`password-toggle ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                    aria-hidden="true"
                    onClick={handleShowPassword}
                  ></i> 
                )}
              </div>

              <div className="mb-4">
                <button 
                  type="submit" 
                  className="btn w-100"
                  disabled={loading}
                  style={{ opacity: loading ? '0.5' : '1' }}
                >
                  {loading ? <SpinnerLoading /> : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
