import axios from 'axios';
import store from '../store';

const baseURL = process.env.NODE_ENV === 'production' ?
  process.env.REACT_APP_SERVER_URL_PRO :
  process.env.REACT_APP_SERVER_URL_DEV;

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  async (config) => {
    try {
      const { userLogin } = store.getState();
      const token = userLogin.userInfo ? userLogin.userInfo.token : null;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Error retrieving user info:', error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;