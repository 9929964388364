import React, { useEffect } from "react";
import { CircularLoading } from "./../LoadingError/Loading";
import Message from './../LoadingError/Error';
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, listOfCategories } from "../../redux/actions/CategoryActions";

const CategoriesTable = ({ onEditClick, onCategoryDelete }) => {
  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const { success: deleteSuccess } = categoryDelete;

  useEffect(() => {
    dispatch(listOfCategories());
  }, [dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      onCategoryDelete(); 
    }
  }, [deleteSuccess, onCategoryDelete]);

  const deleteHandler = (id) => {
    if (window.confirm("Admin, confirm delete?")) {
      dispatch(deleteCategory(id));
  
      onCategoryDelete();
    }
  };  

  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        {loading ? (<CircularLoading />) 
          : error ? (<Message variant="alert-danger">{error}</Message>) 
          : (
          <>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            {/* Table Data */}
            <tbody>
              {categories.map((category, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <b>{category.name}</b>
                  </td>
                  <td className="text-end">
                    <div className="dropdown">
                      <div
                        to="#"
                        data-bs-toggle="dropdown"
                        className="btn btn-light" 
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </div>
                      <div className="dropdown-menu">
                        <div
                          className="dropdown-item"
                          onClick={() => onEditClick(category)}
                        >
                          Edit
                        </div>
                        <div 
                          className="dropdown-item text-danger"
                          onClick={() => deleteHandler(category._id)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default CategoriesTable;
