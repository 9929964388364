import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRouter = ({ component: Component, ...rest }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const {userInfo} = userLogin

  if (userInfo && userInfo.isAdmin) {
    return <Component />;
  } else {
    return <Navigate to="/login" state={{ from: rest.location }} replace />;
  }
};

export default PrivateRouter;
