import React from "react";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/actions/ProductActions";
import { useNavigate } from "react-router-dom";
import { SpinnerLoading } from "../LoadingError/Loading";

const Product = (props) => {

  const { product, loadingDelete, deletingProductId, setDeletingProductId} = props;

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const deleteHandler = (id) => {
    if (window.confirm("Admin, confirm delete ?")) {
      setDeletingProductId(id);
      dispatch(deleteProduct(id))
    } 
  } 

  const handleEditClick = () => {
    navigate(`/addproduct/${product._id}/edit`);
  };

  const productLogoUrl = product.productLogo ? product.productLogo.url : null;

  const firstScreenFlow = product.screensFlow && product.screensFlow.length > 0 ? product.screensFlow[0] : null;
 
  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-4 mb-5 mobile-web-product">
        <div className="product-border">
          <div className="info-wrap">
            <p className="">
              {product.brandName}
            </p>
            <div>
              {productLogoUrl && <img className="product-logo" src={productLogoUrl} alt={product.brandName} />}
            </div>
          </div>
          <div className="card card-product-grid"> 
            <div className="img-wrap">
              {(loadingDelete && deletingProductId === product._id) && (
                <div className="position-right">
                  <SpinnerLoading variant="text-secondary" />
                </div>
              )}
              {firstScreenFlow && (
                <img className="product-image-one" src={firstScreenFlow.url} alt="Product" />
              )}
            </div>
            <div className="dropdown">
              <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i> 
              </div>
              <div className="dropdown-menu dropdown-menu-end">
                <div onClick={() => handleEditClick(product._id)} className="dropdown-item text-primary">
                  <i className="fas fa-pen" aria-hidden="true"></i> Edit
                </div>
                <div onClick={() => deleteHandler(product._id)} className="dropdown-item text-danger">
                  <i className="fas fa-trash-alt" aria-hidden="true"></i> Delete
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;