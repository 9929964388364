import React from "react";

const CircularLoading = () => {
  return (
    <div className="d-flex justify-content-center">
      <div
        className="spinner-border text-success"
        role="status"
        style={{ width: "50px", height: "50px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const SpinnerLoading = ({ variant }) => {
  return (
    <div className="d-flex justify-content-center">
      <div 
        className={`spinner-border ${variant}`}
        role="status"
        style={{ width: "23px", height: "23px", borderWidth: "2px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

SpinnerLoading.defaultProps = {
  variant: "text-white",
};

export { CircularLoading, SpinnerLoading };
