import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRouter from "./PrivateRouter";
import "./App.scss";
import "./responsive.scss";
import "react-toastify/dist/ReactToastify.css";

import HomeScreen from "./pages/HomeScreen";
import ProductScreen from "./pages/productScreen";
import AddProduct from "./pages/AddProduct";
import CategoriesScreen from "./pages/CategoriesScreen";
import Login from "./pages/LoginScreen";
import ProfileScreen from "./pages/profileScreen";
import AdminScreen from "./pages/AdminScreen";
import UsersScreen from "./pages/UsersScreen";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRouter component={HomeScreen} />} />
          <Route path="/products" element={<PrivateRouter component={ProductScreen} />} />
          <Route path="/addproduct" element={<PrivateRouter component={AddProduct} />} />
          <Route path="/addproduct/:id/edit" element={<PrivateRouter component={AddProduct} />} />
          <Route path="/category" element={<PrivateRouter component={CategoriesScreen} />} />
          <Route path="/users" element={<PrivateRouter component={UsersScreen} />} />
          <Route path="/admin" element={<PrivateRouter component={AdminScreen} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<PrivateRouter component={ProfileScreen} />} />
          <Route path="*" element={<PrivateRouter component={NotFound} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
