import API from '../api/index';

import {toast} from "react-toastify"
import { ADMIN_PROFILE_FAIL, ADMIN_PROFILE_REQUEST, ADMIN_PROFILE_SUCCESS, ADMIN_UPDATE_PROFILE_FAIL, ADMIN_UPDATE_PROFILE_REQUEST, ADMIN_UPDATE_PROFILE_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_RESET, USER_LIST_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT } from "../constants/UserConstants";

// ADMIN LOGIN
export const login = (email, password) => async(dispatch) => {
    const ToastObjects = {
        pauseOnFocusLoss : false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 3000,
    }

    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }

        const {data} = await API.post(`/api/users/login`, {email, password}, config);

        if (!data.isAdmin === true) {
            toast.error("only Admin can sign in", ToastObjects)
            dispatch({
                type: USER_LOGIN_FAIL
            });
            dispatch(logout())
        } else {
            dispatch({
                type: USER_LOGIN_SUCCESS, 
                payload: data,
            });  
        }

        localStorage.setItem("userInfo", JSON.stringify(data))

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: message,
        });
    }
}

// LOGOUT
export const logout = () => async(dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({
        type: USER_LOGOUT,
    });
    dispatch({
        type: USER_LIST_RESET,
    });
}

// ADMIN PROFILE DETAILS
export const getUserProfileDetails = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_PROFILE_REQUEST,
        })

        const { userLogin: { userInfo },} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const { data } = await API.get(`/api/users/admin/profile`, config);

        dispatch({
            type: ADMIN_PROFILE_SUCCESS, 
            payload: data,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: ADMIN_PROFILE_FAIL,
            payload: message,
                
        });
    }
}

// ADMIN UPDATE PROFILE
export const updateUserProfile = (user) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_UPDATE_PROFILE_REQUEST,
        })

        const { userLogin: { userInfo },} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const {data} = await API.put(`/api/users/profile`, user, config);

        dispatch({
            type: ADMIN_UPDATE_PROFILE_SUCCESS, 
            payload: data,
        });
        dispatch({
            type: USER_LOGIN_SUCCESS, 
            payload: data,
        });

        localStorage.setItem("userInfo", JSON.stringify(data));

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: ADMIN_UPDATE_PROFILE_FAIL,
            payload: message,
                
        });
    }
}

// ADMIN GET ALL USERS
export const listOfUsers = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const {data} = await API.get(`/api/users`, config);

        dispatch({
            type: USER_LIST_SUCCESS, 
            payload: data,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: USER_LIST_FAIL,
            payload: message,
        });
    }
}