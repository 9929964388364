import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, listOfCategories, updateCategory } from "../../redux/actions/CategoryActions";
import Message from "../LoadingError/Error";
import { CATEGORY_CREATE_RESET } from "../../redux/constants/CategoryConstants";
import { SpinnerLoading } from "../LoadingError/Loading";

const CreateCategory = ({ isEditMode, categoryToEdit, onEditComplete, onCategoryAdded }) => {
  const [categoryName, setCategoryName] = useState("");

  const dispatch = useDispatch();
 
  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { loading, error, category } = categoryCreate;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const { loading: updateLoading, success: updateSuccess, error: updateError } = categoryUpdate;

  useEffect(() => {
    if (isEditMode) { 
      setCategoryName(categoryToEdit.name);
    }
  }, [isEditMode, categoryToEdit]);

  useEffect(() => {
    if (updateSuccess) {
      setCategoryName(""); 
      dispatch(listOfCategories());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    if (category) {
      dispatch({ type: CATEGORY_CREATE_RESET })
      setCategoryName(""); 
      onCategoryAdded();
      dispatch(listOfCategories());
    }
  }, [dispatch, category, onCategoryAdded]);

  const cancelEditHandler = () => {
    setCategoryName("");
    onEditComplete(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(updateCategory(categoryToEdit._id, categoryName));
      onEditComplete(true); 
    } else {
      dispatch(createCategory(categoryName));
    }
  };

  const isButtonDisabled = !categoryName.trim();

  return (
    <div className="col-md-12 col-lg-4">
      {error && <Message variant="alert-danger">{error}</Message>}
      {updateError && <Message variant="alert-danger">{updateError}</Message>}
      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="product_name" className="form-label">
            Name
          </label>
          <input
            type="text"
            placeholder="Category Name"
            className="form-control py-3"
            id="product_name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>

        <div className="d-grid">
          <button
            className={`btn ${isEditMode ? "btn-primary" : "custom-lightgreen"} py-3`} 
            disabled={loading || updateLoading || isButtonDisabled}
          >
            {loading || updateLoading ? <SpinnerLoading /> : (isEditMode ? "Update category" : "Create category")}
          </button>
          {isEditMode && ( 
            <button
              type="button"
              className="btn btn-secondary py-3 my-3"
              onClick={cancelEditHandler}
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;
