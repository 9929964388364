import API from '../api/index';

import { PRODUCT_CREATE_FAIL, PRODUCT_CREATE_REQUEST, PRODUCT_CREATE_SUCCESS, PRODUCT_DELETE_FAIL, PRODUCT_DELETE_FILE_FAIL, PRODUCT_DELETE_FILE_REQUEST, PRODUCT_DELETE_FILE_SUCCESS, PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_RESET, PRODUCT_DELETE_SUCCESS, PRODUCT_EDIT_FAIL, PRODUCT_EDIT_REQUEST, PRODUCT_EDIT_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS } from "../constants/ProductConstants";

import { logout } from './userActions';

// ADMIN GET ALL PRODUCTS
export const listOfProducts = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const {data} = await API.get(`/api/products/all`, config);

        dispatch({
            type: PRODUCT_LIST_SUCCESS, 
            payload: data,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: message,
        });
    }
}

// ADMIN DELETE PRODUCT
export const deleteProduct = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await API.delete(`/api/products/${id}`, config);

        dispatch({
            type: PRODUCT_DELETE_SUCCESS,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload: message,
        });
    }
}

// Action to reset the delete category state
export const resetProductDelete = () => (dispatch) => {
    dispatch({ type: PRODUCT_DELETE_RESET });
};

// ADMIN CREATE PRODUCT
export const createProduct = (myFormData) => async(dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REQUEST,
        }) 

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await API.post(`/api/products/`, myFormData, config);

        dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            payload: data
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: message,
        });
    }
} 

// ADMIN CREATE PRODUCT
export const editProduct = (id, myFormData) => async(dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_EDIT_REQUEST,
        }) 

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await API.put(`/api/products/update/${id}`, myFormData, config);

        dispatch({
            type: PRODUCT_EDIT_SUCCESS,
            payload: data
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: PRODUCT_EDIT_FAIL,
            payload: message,
        });
    }
} 

// ADMIN DELETE ITEM FROM SCREENSFLOW AND VIDEOSFLOW ARRAY 
export const handleDeleteFile = (productId, fileId, field) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DELETE_FILE_REQUEST });

        const response = await API.delete(`api/products/delete-file/${productId}/${fileId}/${field}`);
        
        dispatch({
            type: PRODUCT_DELETE_FILE_SUCCESS,
            payload: response.data 
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FILE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
        console.error("Error deleting file:", error);
    }
};