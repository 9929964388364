import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { listOfUsers } from "../../redux/actions/userActions";
import { CircularLoading } from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const AdminComponent = () => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  useEffect(() => {
    dispatch(listOfUsers());
  }, [dispatch]);

  const adminUsers = users && users.filter((user) => user.isAdmin);

  return (
    <section className="content-main user-list">
      <div className="content-header">
        <h2 className="content-title">Admins</h2>
        <div>
          <Link to="#" className="btn btn-primary">
            <i className="material-icons md-plus"></i> Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4">
        <header className="card-header">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Show 20</option>
                <option>Show 30</option>
                <option>Show 40</option>
                <option>Show all</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Status: all</option>
                <option>Active only</option>
                <option>Disabled</option>
              </select>
            </div>
          </div>
        </header>

        {/* Card */}
        <div className="card-body">
          {loading ? (
            <CircularLoading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <>
              <table className="table user-table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>avatar</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Registered</th>
                  </tr>
                </thead>
                {/* Table Data */}
                <tbody>
                  {adminUsers.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="center">
                          {index + 1}
                        </div>
                      </td>
                      <td>
                        <div className="center">
                          {user.profileImage ? (
                            <img
                              className="img-avatar"
                              src={user.profileImage}
                              alt="User pic"
                            />
                          ) : (
                            <div className="no-profile-image">
                              <div className="center-profile-image">
                                <i className="fas fa-user-times"></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="center">
                          <p className="m-0">{user.name}</p>
                        </div>
                      </td>
                      <td>
                        <div className="center">
                          <p className="email m-0">
                            <a href={`mailto:${user.email}`}>{user.email}</a>
                              {user.verified && (
                                <i className="fas fa-badge-check"></i>
                              )}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="center">
                          <p className="user-date-joined m-0">
                            {moment(user.createdAt).format("llll")}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* nav */}
          <nav className="float-end mt-4" aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item disabled">
                <Link className="page-link" to="#">
                  Previous
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  Next
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default AdminComponent;
