import { configureStore } from '@reduxjs/toolkit';
import { adminProfileDetailsReducer, adminUpdateProfileDetailsReducer, userListReducer, userLoginReducer } from './reducers/UserReducer';
import { productCreateReducer, productDeleteFileReducer, productDeleteReducer, productEditReducer, productListReducer } from './reducers/ProductReducer';
import { categoryCreateReducer, categoryDeleteReducer, categoryListReducer, categoryUpdateReducer } from './reducers/CategoryReducer';

// USER LOGIN
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    adminProfile: adminProfileDetailsReducer,
    adminUpdateProfile: adminUpdateProfileDetailsReducer,
    userList: userListReducer,
    productList: productListReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productEdit: productEditReducer,
    productDeleteFile: productDeleteFileReducer,
    categoryList: categoryListReducer,
    categoryDelete: categoryDeleteReducer,
    categoryCreate: categoryCreateReducer,
    categoryUpdate: categoryUpdateReducer,
  },
  preloadedState: {
    userLogin: {
      userInfo: userInfoFromLocalStorage,
    }
  },
});

export default store;
