import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";
import { listOfProducts, resetProductDelete } from "../../redux/actions/ProductActions";
import { CircularLoading } from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const MainProducts = () => {
  const [selectedFilter, setSelectedFilter] = useState("mobile");
  const [deletingProductId, setDeletingProductId] = useState(null); 
  
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = productDelete;

  useEffect(() => {
    dispatch(listOfProducts());
    dispatch(resetProductDelete());
  }, [dispatch, successDelete]);

  useEffect(() => {
    const ToastObjects = {
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
      autoClose: 3000,
    };

    if (successDelete) {
      toast.success('Product deleted successfully!', ToastObjects);
    }
  }, [successDelete]);
  
  const filteredProducts = products.filter(product => {
    if (selectedFilter === "mobile") {
      return product.platform === "Mobile";
    } else if (selectedFilter === "web") {
      return product.platform === "Web";
    }
    return true;
  });

  return (
    <>
      <Toast />
      <section className="content-main all-product">
        <div className="content-header">
          <h2 className="content-title">Products</h2>
          <div>
            <Link to="/addproduct" className="btn btn-primary">
              Add product
            </Link>
          </div>
        </div>

        <div className="card mb-4 shadow-sm">
          <header className="card-header bg-white ">
            <div className="row gx-3 py-3">
              <div className="col-lg-4 col-md-6 me-auto ">
                <input
                  type="search"
                  placeholder="Search..."
                  className="form-control p-2"
                />
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>All category</option>
                  <option>Electronics</option>
                  <option>Clothings</option>
                  <option>Something else</option>
                </select>
              </div>
              <div className="col-lg-2 col-12 col-md-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={`btn btn-md me-2 ${selectedFilter === "mobile" ? "btn-secondary" : "btn-outline-secondary"}`}
                    onClick={() => setSelectedFilter("mobile")}
                  >
                    Mobile
                  </button>
                  <button
                    className={`btn btn-md ${selectedFilter === "web" ? "btn-secondary" : "btn-outline-secondary"}`}
                    onClick={() => setSelectedFilter("web")}
                  >
                    Web
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="card-body">
            {errorDelete && (<Message variant="alert-danger">{errorDelete}</Message>)}
            {loading ? (<CircularLoading />)
            : error ? (<Message variant="alert-danger">{error}</Message>)
            : (
              <div className="row">
                {filteredProducts.map((product) => (
                  <Product 
                    product={product} 
                    key={product._id} 
                    loadingDelete={loadingDelete} 
                    deletingProductId={deletingProductId}
                    setDeletingProductId={setDeletingProductId}
                  />
                ))}
              </div>
            )}
            <nav className="float-end mt-4" aria-label="Page navigation">
              <ul className="pagination">
                <li className="page-item disabled">
                  <Link className="page-link" to="#">
                    Previous
                  </Link>
                </li>
                <li className="page-item active">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>   
    </>
  );
};

export default MainProducts;
