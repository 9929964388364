import React, { useEffect, useState } from 'react';

const ScreensCategoryModal = ({ show, handleClose, category, dbCategory, onSave, index, editMode }) => {
  const [categoryName, setCategoryName] = useState(editMode ? (dbCategory[index] ? dbCategory[index] : '') : (category ? category : ''));

  useEffect(() => {
    setCategoryName(editMode ? dbCategory[index] || '' : category || ''); 
  }, [dbCategory, category, editMode, index]);

  const handleSave = () => {
    onSave(categoryName, index);  
    handleClose();
  };

  return (
    <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content"> 
          <div className="modal-header">
            {editMode ? (
              <h5 className="modal-title">Edit Category</h5>
            ) : (
              <h5 className="modal-title">{category ? 'Edit Category' : 'Add Category'}</h5>
            )}
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <input type="text" className="form-control mb-3" placeholder="Category Name" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreensCategoryModal;
