import React, { useEffect } from "react";
import { toast } from "react-toastify";

import Toast from '../components/LoadingError/Toast';
import Header from "../components/Header";
import Main from "../components/Home/Main";
import Sidebar from "./../components/sidebar";
import { useLocation } from "react-router-dom";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const HomeScreen = () => {
  const location = useLocation();
  const { userInfo } = location.state || {};

  useEffect(() => {
    if (userInfo) {
      toast.success(`Welcome, ${userInfo.name}!`, ToastObjects);
    }
  }, [userInfo]);

  return (
    <>
      <Toast />
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <Main />
      </main>
    </>
  );
};

export default HomeScreen;
