export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"; 
export const USER_LOGOUT = "USER_LOGOUT";

export const ADMIN_PROFILE_REQUEST = "ADMIN_PROFILE_REQUEST";
export const ADMIN_PROFILE_SUCCESS = "ADMIN_PROFILE_SUCCESS";
export const ADMIN_PROFILE_FAIL = "ADMIN_PROFILE_FAIL";
export const ADMIN_PROFILE_RESET = "ADMIN_PROFILE_RESET";

export const ADMIN_UPDATE_PROFILE_REQUEST = "ADMIN_UPDATE_PROFILE_REQUEST";
export const ADMIN_UPDATE_PROFILE_SUCCESS = "ADMIN_UPDATE_PROFILE_SUCCESS";
export const ADMIN_UPDATE_PROFILE_FAIL = "ADMIN_UPDATE_PROFILE_FAIL";
export const ADMIN_UPDATE_PROFILE_RESET = "ADMIN_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL"; 
export const USER_LIST_RESET = "USER_LIST_RESET"; 