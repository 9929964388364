import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userActions";

const Header = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin;

  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  const getInitials = (user) => {
    const propertyToUse = user.name; 
  
    if (propertyToUse) {
      const propertyArray = propertyToUse.split(' '); 
      return propertyArray.slice(0, 2).map(word => word[0]).join('').toUpperCase();
    }
  
    return '';
  }; 

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header className="main-header navbar">
      <div className="col-search">
        <form className="searchform">
          <div className="input-group">
            <input
              list="search_terms"
              type="text"
              className="form-control"
              placeholder="Search term"
            />
            <button className="btn btn-light bg" type="button">
              <i className="far fa-search"></i>
            </button>
          </div>
          <datalist id="search_terms">
            <option value="Products" />
            <option value="New orders" />
            <option value="Apple iphone" />
            <option value="Ahmed Hassan" />
          </datalist>
        </form>
      </div>
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile me-auto"
          data-trigger="#offcanvas_aside"
        >
          <i className="md-28 fas fa-bars"></i>
        </button>
        <ul className="nav">
          <li className="nav-item">
            <Link className={`nav-link btn-icon `} title="Dark mode" to="#">
              <i className="fas fa-moon"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link btn-icon" to="#">
              <i className="fas fa-bell"></i>
            </Link>
          </li>
          <li className="nav-item">
            <p className="m-0 px-3 text-align-center">Hi, {userInfo.name}</p>
          </li>
          <li className="dropdown nav-item admin-avatar">
            <div className="dropdown-toggle" data-bs-toggle="dropdown">
              {userInfo.profileImage ? (
                <img src={userInfo.profileImage} alt="Profile" className="profile-image" /> 
                ) : (
                <div className="round-cover-avatar">
                  <h1>{getInitials(userInfo)}</h1>
                </div>
              )}
            </div>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to="/profile">
                My profile
              </Link>
              <div onClick={logoutHandler} className="dropdown-item text-danger logout">
                logout
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
