import API from '../api/index';

import { CATEGORY_CREATE_FAIL, CATEGORY_CREATE_REQUEST, CATEGORY_CREATE_SUCCESS, CATEGORY_DELETE_FAIL, CATEGORY_DELETE_REQUEST, CATEGORY_DELETE_RESET, CATEGORY_DELETE_SUCCESS, CATEGORY_LIST_FAIL, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, CATEGORY_UPDATE_FAIL, CATEGORY_UPDATE_REQUEST, CATEGORY_UPDATE_SUCCESS } from "../constants/CategoryConstants";

import { logout } from './userActions';

// ADMIN GET ALL CATEGORIES
export const listOfCategories = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORY_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const {data} = await API.get(`/api/categories`, config);

        dispatch({
            type: CATEGORY_LIST_SUCCESS, 
            payload: data,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: CATEGORY_LIST_FAIL,
            payload: message,
        });
    }
}

// ADMIN CREATE CATEGORY
export const createCategory = (name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_CREATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await API.post(`/api/categories/createcategory`, { name }, config);
  
      dispatch({
        type: CATEGORY_CREATE_SUCCESS,
        payload: data.category,
      });
  
    } catch (error) {
      const message = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  
      dispatch({
        type: CATEGORY_CREATE_FAIL,
        payload: message,
      });
    }
};

// ADMIN UPDATE CATEGORY
export const updateCategory = (id, name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_UPDATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await API.put(`/api/categories/updatecategory/${id}`, { name }, config);
  
      dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: data,
      });
  
    } catch (error) {
      const message = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  
      dispatch({
        type: CATEGORY_UPDATE_FAIL,
        payload: message,
      });
    }
};

// ADMIN DELETE CATEGORY
export const deleteCategory = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORY_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await API.delete(`/api/categories/${id}`, config);

        dispatch({
            type: CATEGORY_DELETE_SUCCESS,
        });

    } catch (error) {
        const message = error.response && error.response.data.message 
        ? error.response.data.message 
        : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout())
        }
        dispatch({
            type: CATEGORY_DELETE_FAIL,
            payload: message,
        });
    }
}
  
// Action to reset the delete category state
export const resetCategoryDelete = () => (dispatch) => {
    dispatch({ type: CATEGORY_DELETE_RESET });
};