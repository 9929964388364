import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";

import FileUpload from "./FileUpload";
import AddItemModal from './AddItemModal';
import API from '../../redux/api/index';
import { PRODUCT_CREATE_RESET } from "../../redux/constants/ProductConstants";
import { createProduct, editProduct, handleDeleteFile } from "../../redux/actions/ProductActions";
import Toast from "../LoadingError/Toast";
import Message from "../LoadingError/Error";
import { SpinnerLoading } from "../LoadingError/Loading";
import { FunctionalityForm, UsabilityForm, VisualDesignForm } from "./RatingForm";
import ScreensCategoryModal from "./ScreensCategoryModal";

const initialState = {
  version: '',
  brandName: '',
  categories: ['All'],
  platform: 'Mobile',
  productLogo: null,
  description: {
    about: '',
    website: '',
    location: '',
    employees: '',
    funding: '',
    foundedDate: '',
    founders: [],
    email: '',
  },
  usability: 0,
  functionality: 0,
  visualDesign: 0,
  screensFlow: [],
  videosFlow: [],
};

const ToastObjects = {
  pauseOnFocusLoss : false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 3000,
}

const AddProductMain = () => {
  const [formData, setFormData] = useState(initialState);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(['All']);
  const [platforms, setPlatforms] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingPlatforms, setLoadingPlatforms] = useState(true);
  const [newFounder, setNewFounder] = useState('');
  const [editingFounder, setEditingFounder] = useState(null);
  const [editedFounder, setEditedFounder] = useState('');

  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [selectedModalFile, setSelectedModalFile] = useState(null);

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [screensCategoryModalVisible, setScreensCategoryModalVisible] = useState(false);
  const [screensCategory, setScreensCategory] = useState("")
  const [imageCategories, setImageCategories] = useState(Array.from({ length: selectedImages.length }, () => ""));

  const [editMode, setEditMode] = useState(false);
  const [productDataToEdit, setProductDataToEdit] = useState(null);
  const [productData, setProductData] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch()
  const fileInputRef = useRef(null);

  const productList = useSelector(state => state.productList);
  const { products } = productList;

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product: createdProduct } = productCreate;

  const productDeleteFileReducer = useSelector((state) => state.productDeleteFileReducer || {});
  const { error: deleteFileError } = productDeleteFileReducer;

  const productEdit = useSelector((state) => state.productEdit); 
  const { loading: editProductLoading, success: editProductSuccess, error: editProductError } = productEdit;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoadingCategories(true);
        const categoriesResponse = await API.get(
          "/api/categories"
        );
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("Error fetching categories:", error.message);
      } finally {
        setLoadingCategories(false);
      }
    };

    const fetchPlatforms = async () => {
      try {
        setLoadingPlatforms(true);
        const platformsResponse = await API.get(
          "/api/products/productplatforms"
        );
        setPlatforms(platformsResponse.data);
      } catch (error) {
        console.error("Error fetching platforms:", error.message);
      } finally {
        setLoadingPlatforms(false);
      }
    };

    fetchCategories();
    fetchPlatforms();
  }, []);
  
  useEffect(() => {
    if (createdProduct) {
      dispatch({type: PRODUCT_CREATE_RESET})
      toast.success("Product Added", ToastObjects)
      setFormData(initialState)
      setSelectedCategories(['All']);
      setSelectedImages([])
      setSelectedVideos([])
    }

    if (editProductSuccess) {
      dispatch({type: PRODUCT_CREATE_RESET})
      toast.success("Product Updated", ToastObjects)
    }
  }, [createdProduct, editProductSuccess, dispatch])

  useEffect(() => {
    if (productData && productData.screensFlow) {
      setSelectedImages(productData.screensFlow);
    }
    if (productData && productData.videosFlow) {
      setSelectedVideos(productData.videosFlow);
    }
  }, [productData]);

  useEffect(() => {
    if (id && products.length > 0) {
      const productToEdit = products.find((product) => product._id === id);
      if (productToEdit) {
        setProductData(productToEdit);
        setProductDataToEdit(productToEdit);
        setEditMode(true);
        setFormData({
          version: productToEdit.version,
          brandName: productToEdit.brandName,
          categories: productToEdit.categories.map(category => category.name) || [], 
          platform: productToEdit.platform || 'Mobile',
          description: {
            about: productToEdit.description?.about || '',
            website: productToEdit.description?.website || '',
            location: productToEdit.description?.location || '',
            employees: productToEdit.description?.employees || '',
            funding: productToEdit.description?.funding || '',
            foundedDate: productToEdit.description?.foundedDate || '',
            founders: productToEdit.description?.founders || [],
            email: productToEdit.description?.email || '',
          },
          usability: productToEdit.usability || 0,
          functionality: productToEdit.functionality || 0,
          visualDesign: productToEdit.visualDesign || 0,
          productLogo: productToEdit.productLogo ? productToEdit.productLogo : null, 
          screensFlow: productToEdit.screensFlow || [],
          screensCategory: productToEdit.screensFlow.map(screen => screen.screensCategory),
          videosFlow: productToEdit.videosFlow || [],
        });  
        setSelectedCategories(productToEdit.categories.map(category => category.name)); 
      }
    }
  }, [id, products]);
  
  const handleChange = (e, nestedKey = null) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      if (nestedKey) {
        if (nestedKey === 'description' && name === 'founders') {
          return {
            ...prevFormData,
            [nestedKey]: { ...prevFormData[nestedKey], [name]: value },
          };
        }

        return {
          ...prevFormData,
          [nestedKey]: { ...prevFormData[nestedKey], [name]: value },
        };
      }

      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const handleAddFounder = () => {
    if (newFounder.trim() !== '') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        description: {
          ...prevFormData.description,
          founders: [...prevFormData.description.founders, newFounder.trim()],
        },
      }));
      setNewFounder('');
    }
  };

  const handleEditFounder = (founderToEdit) => {
    setEditingFounder(founderToEdit);
    setEditedFounder(founderToEdit);
  };

  const handleSaveFounder = () => {
    const index = formData.description.founders.indexOf(editingFounder);
    if (index !== -1 && editedFounder.trim() !== '') {
      const updatedFounders = [...formData.description.founders];
      updatedFounders[index] = editedFounder.trim();
      setFormData((prevFormData) => ({
        ...prevFormData,
        description: {
          ...prevFormData.description,
          founders: updatedFounders,
        },
      }));
      setEditingFounder(null);
      setEditedFounder('');
    }
  };

  const handleRemoveFounder = (founderToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: {
        ...prevFormData.description,
        founders: prevFormData.description.founders.filter(
          (founder) => founder !== founderToRemove
        ),
      },
    }));
  }; 
  
  const handleCategoryChange = (e) => {
    const selectedOption = e.target.value;
  
    if (!selectedCategories.includes(selectedOption)) {
      setSelectedCategories(prevSelectedCategories => [...prevSelectedCategories, selectedOption]);
      setFormData({
        ...formData,
        categories: [...selectedCategories, selectedOption],
      });
    }
  };   
  
  const handleRemoveCategory = (categoryToRemove) => {
    if (categoryToRemove !== 'All') {
      const updatedCategories = selectedCategories.filter(category => category !== categoryToRemove);
      setSelectedCategories(updatedCategories);
      setFormData({
        ...formData,
        categories: updatedCategories,
      });
    }
  };  

  const handleRatingChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: Number(value),
    }));
  };  

  const handleLogoChange = (selectedFiles) => {
    if (selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0]; 
      if (selectedFile instanceof File || selectedFile instanceof Blob) {
        setFormData({
          ...formData,
          productLogo: selectedFile,
        });
      } else {
        console.error("Invalid file format or object");
      }
    }
  };   
  
  const handleClearLogo = () => {
    setFormData({
      ...formData,
      productLogo: null,
    });
  }; 

  const handleReplaceLogo = (newFile) => {
    if (newFile instanceof File || newFile instanceof Blob) {
      setFormData({
        ...formData,
        productLogo: newFile,
      });
    } else {
      console.error("Invalid file format or object");
    }
  };
  
  const handleFileDelete = (productId, fileId, field) => {
    dispatch(handleDeleteFile(productId, fileId, field));
  };

  const handleImageChange = (selectedFiles) => {
    const newImages = Array.from(selectedFiles);
  
    newImages.forEach((file) => {
      if (file instanceof File || file instanceof Blob) {
        const newImageObject = { file, category: '' };
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          screensFlow: [...prevFormData.screensFlow, newImageObject],
        }));
      } else {
        console.error('Invalid file format or object');
      }
    });
  
    setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...newImages]);
  };      

  const handleOpenScreensCategoryModal = (index) => {
    setSelectedImageIndex(index); 
    setScreensCategoryModalVisible(true);
    const category = imageCategories[index] || "";
    setScreensCategory(category);
  };    
  
  const handleCloseScreensCategoryModal = () => {
    setScreensCategoryModalVisible(false);
  };
  
  const handleSaveScreensCategory = (category) => {
    if (selectedImageIndex !== null) {
      setFormData((prevFormData) => {
        const updatedScreensFlow = [...prevFormData.screensFlow];
        updatedScreensFlow[selectedImageIndex] = {
          ...updatedScreensFlow[selectedImageIndex],
          category: category,
        };
        return {
          ...prevFormData,
          screensFlow: updatedScreensFlow,
        };
      });
  
      setImageCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[selectedImageIndex] = category;
        return updatedCategories;
      });
  
      setScreensCategory(category);
  
      setSelectedImageIndex(null);
    }
    handleCloseScreensCategoryModal();
  };  
    
  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  
    setFormData((prevFormData) => {
      const updatedScreensFlow = [...prevFormData.screensFlow];
      updatedScreensFlow.splice(index, 1);
      return {
        ...prevFormData,
        screensFlow: updatedScreensFlow,
      };
    });
  };

  const handleVideoChange = (selectedFiles) => {
    const newVideos = Array.from(selectedFiles);
    newVideos.forEach((file) => {
      if (file instanceof File || file instanceof Blob) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            videosFlow: [
              ...prevFormData.videosFlow,
              file
            ],
          };
        });
      } else {
        console.error('Invalid file format or object');
      }
    });
    setSelectedVideos((prevSelectedVideos) => [
      ...prevSelectedVideos,
      ...newVideos
    ]);
  }; 
  
  const handleRemoveVideo = (index) => {
    const updatedVideos = [...selectedVideos];
    updatedVideos.splice(index, 1);
    setSelectedVideos(updatedVideos);
  
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        videosFlow: prevFormData.videosFlow.filter((video, i) => i !== index),
      };
    });
  };  

  const handleOpenModal = (fileType, selectedModalFile) => {
    if (fileType === 'image') {
      setImageModalVisible(true);
      setVideoModalVisible(false);
    } else if (fileType === 'video') {
      setImageModalVisible(false);
      setVideoModalVisible(true);
    }
    
    if (selectedModalFile) {
      setSelectedModalFile(selectedModalFile);
    } else {
      setSelectedModalFile(null);
    }
  };
  
  const handleReplaceFile = (fileType, newFile) => {
    if (fileType === 'image') {
      const updatedImages = selectedImages.map(image => {
        if (image.name === selectedModalFile.name) {
          return newFile;
        }
        return image;
      });
      setSelectedImages(updatedImages);
    } else if (fileType === 'video') {
      const updatedVideos = selectedVideos.map(video => {
        if (video.name === selectedModalFile.name) {
          return newFile;
        }
        return video;
      });
      setSelectedVideos(updatedVideos);
    }
  
    setSelectedModalFile(newFile);
  };   
  
  const handleCloseModal = () => {
    setImageModalVisible(false);
    setVideoModalVisible(false);
    setSelectedModalFile(null);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
  
    const myFormData = new FormData();

    myFormData.append('version', formData.version);
    myFormData.append('brandName', formData.brandName);

    formData.categories.forEach((category) => {
      myFormData.append('categories[]', category);
    });

    myFormData.append('platform', formData.platform);

    if (formData.description) {
      myFormData.append('description[about]', formData.description.about || '');
      myFormData.append('description[website]', formData.description.website || '');
      myFormData.append('description[location]', formData.description.location || '');
      myFormData.append('description[employees]', formData.description.employees || '');
      myFormData.append('description[funding]', formData.description.funding || '');
      myFormData.append('description[foundedDate]', formData.description.foundedDate || '');
      myFormData.append('description[email]', formData.description.email || '');

      formData.description.founders.forEach((founder, index) => {
        myFormData.append(`description[founders][${index}]`, founder);
      });
    }

    myFormData.append('usability', formData.usability);
    myFormData.append('functionality', formData.functionality);
    myFormData.append('visualDesign', formData.visualDesign);

    if (formData.productLogo) {
      myFormData.append('productLogo', formData.productLogo); 
    }       
  
    selectedImages.forEach((file, category) => {
      myFormData.append('screensFlow', file);
      myFormData.append('screensCategoryIndex', category);
      const correspondingCategory = formData.screensFlow.find((fileObject) => fileObject.file === file)?.category;
      myFormData.append('screensCategory', correspondingCategory);
      console.log(`Category Index: ${category}, Corresponding Category: ${correspondingCategory}`);
    });   

    if(editMode) {
      formData.screensFlow.forEach((file, category) => {
        myFormData.append('screensFlow', file);
        myFormData.append('screensCategoryIndex', category); 
        const correspondingCategory = formData.screensFlow.find((fileObject) => fileObject.file === file)?.category;
        myFormData.append('screensCategory', correspondingCategory);
      });
    }
   
    selectedVideos.forEach((file) => {
      myFormData.append('videosFlow', file);
    });   
  
    if (editMode) {
      dispatch(editProduct(productDataToEdit._id, myFormData));
    } else {
      dispatch(createProduct(myFormData));
    }
    console.log("FormData sent to backend API:", formData);
  };  
  
  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler} encType="multipart/form-data" className="add-product-form">
          <div className="content-header add-product">
            <Link to="/products" className="back-button d-flex align-items-center gap-2 text-decoration-none">
              Back to products
            </Link>
            <h2 className="content-title my-3">{editMode ? "Edit product" : "Add product"}</h2>
            <div>
              <button 
                type="submit"  
                className="btn btn-primary"
                disabled={loading || editProductLoading}
                style={{ opacity: loading || editProductLoading ? '0.5' : '1' }}
              >
                {loading || editProductLoading ? <SpinnerLoading /> : (editMode ? "Update product" : "Publish product")}
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message> }
                  {deleteFileError && <Message variant="alert-danger">{deleteFileError}</Message> }
                  {editProductError && <Message variant="alert-danger">{editProductError}</Message> }
                  <div className="mb-4">
                    <label htmlFor="product_version" className="form-label">Version</label>
                    <input
                      type="text"
                      placeholder="Enter version"
                      className="form-control"
                      id="product_version"
                      required
                      name="version"
                      value={formData.version}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Brand Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type a unique name here"
                      className="form-control"
                      id="product_title"
                      required
                      name="brandName"
                      value={formData.brandName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Category
                    </label>
                    <select
                      className="mx-4"
                      value={formData.categories}
                      onChange={handleCategoryChange}
                    >
                      <option value="All" style={{ fontWeight: 'bold' }}>
                        {loadingCategories ? "Loading..." : "Select Categories"}
                      </option>
                      {categories.map((category) => (
                        <option
                          key={category._id}
                          value={category.name}
                          style={{
                            fontWeight: selectedCategories.includes(category.name) ? "bold" : "normal",
                          }}
                        >
                          {category.name}
                        </option>
                      ))}
                    </select>
                    <div className="selected-categories">
                      <div className="selected-categories-wrap">
                        {selectedCategories.map((category, index) => (
                          <div className="selected-category" key={index}>
                            <p>{category}</p> 
                            {index !== 0 && ( 
                              <div className="remove-category-button" onClick={() => handleRemoveCategory(category)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Platform
                    </label>
                    <select
                      className="mx-4"
                      name="platform"
                      value={formData.platform}
                      onChange={handleChange}
                    >
                      {loadingPlatforms ? (
                        <option value="Mobile">Loading...</option>
                      ) : (
                        platforms.map((platform) => (
                          <option key={platform} value={platform}>
                            {platform}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">About</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="about"
                      value={formData.description && formData.description.about ? formData.description.about : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="website" className="form-label">
                      Website
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="website" 
                      value={formData.description && formData.description.website ? formData.description.website : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      location
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="location"
                      value={formData.description && formData.description.location ? formData.description.location : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Employees
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="employees"
                      value={formData.description && formData.description.employees ? formData.description.employees : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Funding
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="funding"
                      value={formData.description && formData.description.funding ? formData.description.funding : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      E-mail
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="email"
                      value={formData.description && formData.description.email ? formData.description.email : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Founded Date
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="foundedDate"
                      value={formData.description && formData.description.foundedDate ? formData.description.foundedDate : ''}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="founders" className="form-label">
                      Founders
                    </label>
                    <div className="mb-2">
                      {formData.description?.founders?.map((founder) => (
                        <div key={founder} className="founder-row d-flex align-items-center">
                          {editingFounder === founder ? (
                            <>
                              <input
                                type="text"
                                className="form-control me-2"
                                value={editedFounder}
                                onChange={(e) => setEditedFounder(e.target.value)}
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary me-2"
                                onClick={handleSaveFounder}
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            <>
                              <p className="me-2">{founder}</p>
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary me-2"
                                  onClick={() => handleEditFounder(founder)}
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-warning"
                                  onClick={() => handleRemoveFounder(founder)}
                                >
                                  Remove
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="d-flex">
                      <input
                        type="text"
                        placeholder="Type here"
                        className="form-control"
                        id="founders"
                        name="founders"
                        value={newFounder}
                        onChange={(e) => setNewFounder(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary ms-2"
                        onClick={handleAddFounder}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="usability">
                        <p className="mb-1">Usability</p>
                        <UsabilityForm usability={formData.usability} onRatingChange={handleRatingChange} />
                    </div>
                    <div className="functionality">
                        <p className="mb-1">Functionality</p>
                        <FunctionalityForm functionality={formData.functionality} onRatingChange={handleRatingChange} />
                    </div>
                    <div className="visualDesign">
                        <p className="mb-1">visualDesign</p>
                        <VisualDesignForm visualDesign={formData.visualDesign} onRatingChange={handleRatingChange} /> 
                    </div>
                  </div>
                  <div className="mb-4 logo">
                    <label className="form-label">Logo</label>
                    <br />
                    {formData.productLogo ? (
                      <div className="selected-item">
                        {editMode ? (
                          <img src={formData.productLogo.url || URL.createObjectURL(formData.productLogo)} alt="Selected Logo" />
                        ) : (
                          <img src={URL.createObjectURL(formData.productLogo)} alt="Selected Logo" />
                        )}
                        <div className="dropdown">
                          <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </div>
                          <div className="dropdown-menu dropdown-menu-end">
                            <div
                              className="dropdown-item text-primary"
                              onClick={() => {
                                fileInputRef.current.click();
                              }}
                            >
                              <i className="fa fa-upload" aria-hidden="true"></i>
                              Replace
                            </div>
                            <div
                              className="dropdown-item text-danger"
                              onClick={handleClearLogo}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                              Clear Field
                            </div>
                            <input
                                type="file"
                                id="files"
                                name="productLogo"
                                accept='image/*'
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={(e) => handleReplaceLogo(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                        <FileUpload fileInputRef={fileInputRef} handleFileChange={handleLogoChange} fileType="image" />
                    )}
                  </div>
                  <div className="mb-4 screens with-modal">
                    <label className="form-label">Screens</label>
                    <br />
                    <div className="selected-images">
                      {editMode ? (
                        formData && formData.screensFlow.map((screen, index) => (
                          <div key={index} className="selected-image">
                            <div className="selected-index">
                              <p>{index + 1}</p>
                            </div>
                            <div className="selected-name" onClick={() => handleOpenModal('image', screen)}>
                              {screen && (
                                <>
                                  <img src={screen instanceof File ? URL.createObjectURL(screen) : (screen.url || '')} alt={`Selected screen + ${index}`} />
                                  <p className="screen-name m-0">
                                    {screen.filename && screen.filename.length > 12
                                      ? screen.filename.slice(0, 12) + " .... " + screen.filename.slice(-7)
                                      : screen.filename || screen.name}
                                  </p>
                                  {formData.screensCategory && formData.screensCategory[index] && (
                                    <p className="screen-category m-0">
                                      Category - {formData.screensCategory[index]}
                                    </p>
                                  )}
                                  {imageCategories && imageCategories[index] && (
                                    <p className="screen-category m-0">
                                      Category - {imageCategories[index]}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="dropdown">
                              <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </div>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div
                                  className="dropdown-item text-secondary"
                                  onClick={() => {
                                    handleOpenScreensCategoryModal(index)
                                  }}
                                >
                                  <i className={!screen || !imageCategories[index] ? 'fa fa-plus' : 'fas fa-pen'} aria-hidden="true"></i>
                                  {!screen || !imageCategories[index] ? 'Add Category' : 'Edit Category'}
                                </div>
                                <div
                                  className="dropdown-item text-danger"
                                  onClick={() => {
                                    handleRemoveImage(index);
                                    handleFileDelete(productData._id, screen._id, 'screensFlow');
                                  }}
                                >
                                  <i className="fa fa-trash-alt" aria-hidden="true"></i>
                                  delete
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        selectedImages.map((image, index) => (
                          <div key={index} className="selected-image">
                            <div className="selected-index">
                              <p>{index + 1}</p>
                            </div>
                            <div className="selected-name" onClick={() => handleOpenModal('image', image)}>
                              <img src={image ? URL.createObjectURL(image) : ''} alt={`Selected screen + ${index}`} />
                              <p className="screen-name m-0">
                                {image.name.length > 12
                                  ? image.name.slice(0, 12) + " .... " + image.name.slice(-7)
                                  : image.name}
                              </p>
                              {imageCategories[index] && (
                                <p className="screen-category m-0">Category - {imageCategories[index]}</p>
                              )}
                            </div>
                            <div className="dropdown">
                              <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </div>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div
                                  className="dropdown-item text-secondary"
                                  onClick={() => {
                                    handleOpenScreensCategoryModal(index)
                                  }}
                                >
                                  <i className={imageCategories[index] ? 'fas fa-pen' : 'fa fa-plus'} aria-hidden="true"></i>
                                  {imageCategories[index] ? 'Edit Category' : 'Add Category'}
                                </div>
                                <div
                                  className="dropdown-item text-danger"
                                  onClick={() => {
                                    handleRemoveImage(index);
                                  }}
                                >
                                  <i className="fa fa-trash-alt" aria-hidden="true"></i>
                                  delete
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <button 
                      type="button" 
                      className="btn btn-outline-secondary" 
                      onClick={() => handleOpenModal('image')}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add Item(s)
                    </button>
                    <ScreensCategoryModal
                      show={screensCategoryModalVisible}
                      handleClose={handleCloseScreensCategoryModal}
                      category={screensCategory} 
                      dbCategory={formData.screensCategory}
                      onSave={handleSaveScreensCategory}
                      index={selectedImageIndex}
                      editMode={editMode}
                    />
                    <AddItemModal 
                      show={imageModalVisible}
                      handleClose={handleCloseModal}
                      fileType="image"
                      accept="image/*"
                      name="screensFlow"
                      handleFileChange={handleImageChange}
                      selectedModalFile={selectedModalFile}
                      handleReplaceFile={(newFile) => handleReplaceFile('image', newFile)}
                      setSelectedImages={setSelectedImages}
                      editMode={editMode}
                      formData={formData}
                    />
                  </div>
                  <div className="mb-4 videos with-modal">
                    <label className="form-label">Videos</label>
                    <br />
                    <div className="selected-videos">
                      {editMode ? (
                        formData && formData.videosFlow.map((video, index) => (
                          <div key={index} className="selected-video">
                            <div className="selected-index">
                              <p>{index + 1}</p>
                            </div>
                            <div className="selected-name" onClick={() => handleOpenModal('video', video)}>
                              <video controls>
                                <source src={video.url || URL.createObjectURL(video)} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <p className="m-0">{video.filename || video.name}</p>
                            </div>
                            <div className="dropdown">
                              <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </div>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div 
                                  className="dropdown-item text-danger" 
                                  onClick={() => {
                                    handleRemoveVideo(index);
                                    handleFileDelete(productData._id, video._id, 'videosFlow');
                                  }}
                                >
                                  <i className="fa fa-trash-alt" aria-hidden="true"></i>
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        selectedVideos.map((video, index) => (
                          <div key={index} className="selected-video">
                            <div className="selected-index">
                              <p>{index + 1}</p>
                            </div>
                            <div className="selected-name" onClick={() => handleOpenModal('video', video)}>
                              <video controls>
                                <source src={video ? URL.createObjectURL(video) : ''} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <p className="m-0">{video.name}</p>
                            </div>
                            <div className="dropdown">
                              <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </div>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div 
                                  className="dropdown-item text-danger" 
                                  onClick={() => {
                                    handleRemoveVideo(index);
                                  }}
                                >
                                  <i className="fa fa-trash-alt" aria-hidden="true"></i>
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <button 
                      type="button" 
                      className="btn btn-outline-secondary" 
                      onClick={() => handleOpenModal('video')}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add Item(s)
                    </button> 
                    <AddItemModal 
                      show={videoModalVisible}
                      handleClose={handleCloseModal}
                      fileType="video"
                      accept="video/*"
                      name="videosFlow"
                      handleFileChange={handleVideoChange}
                      selectedModalFile={selectedModalFile} 
                      handleReplaceFile={(newFile) => handleReplaceFile('video', newFile)}
                      setSelectedVideos={setSelectedVideos}
                      editMode={editMode}
                      formData={formData}
                    />
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </form> 
      </section>
    </>
  );
};

export default AddProductMain;