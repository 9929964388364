import React, { useEffect, useRef, useState } from 'react';
import FileUpload from './FileUpload';

const AddItemModal = ({
  show,
  handleClose,
  accept,
  fileType,
  name,
  handleFileChange,
  selectedModalFile,
  handleReplaceFile,
  editMode,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setSelectedFile(selectedModalFile);
  }, [selectedModalFile, show]);

  const handleChangeFile = (file) => {
    setSelectedFile(file);
    handleFileChange(file, fileType);
    handleClose();
  };

  return (
    <div className={`custom-modal ${show ? 'show' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
        <hr />
        <div className="modal-body">
          <p>{fileType === 'image' ? 'Image' : 'Video'}</p>
          {selectedFile && (selectedFile.name || selectedFile.filename) ? (
            <div className="selected-item">
              {fileType === 'image' ? (
                <>
                  {editMode ? (
                    <img src={selectedFile instanceof File ? URL.createObjectURL(selectedFile) : selectedFile.url} alt="Selected File" />
                  ) : (
                    <img src={URL.createObjectURL(selectedFile)} alt="Selected File" />
                  )}
                </>
              ) : (
                <>
                  {editMode ? (
                    <video controls>
                      <source src={selectedFile instanceof File ? URL.createObjectURL(selectedFile) : selectedFile.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <video controls>
                      <source src={URL.createObjectURL(selectedFile)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )}
              <div className="dropdown">
                <div className="dropdown-toggle ham-menu" data-bs-toggle="dropdown">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                <div className="dropdown-menu dropdown-menu-end">
                  {/* <div className="dropdown-item text-primary" onClick={handleOpenFileInput}>
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    Replace
                  </div> */}
                  <input
                    type="file"
                    id={`file-input-${fileType}`}
                    accept={accept}
                    name={name}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(e) => {
                      const newFile = e.target.files[0];
                      if (newFile) {
                        setSelectedFile(newFile);
                        handleReplaceFile(newFile);
                        handleChangeFile(newFile);
                      } else {
                        setSelectedFile(null);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <FileUpload
              fileInputRef={fileInputRef}
              handleFileChange={(file) => {
                setSelectedFile(file);
                handleChangeFile(file);
              }}
              fileType={fileType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddItemModal;
